import { Component, HostListener, OnInit } from '@angular/core';
import { element } from 'protractor';
import { Observable } from 'rxjs';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { ContentfulService } from 'src/app/services/contentful.service';
import { Document } from '@contentful/rich-text-types';

@Component({
  selector: 'app-subresource',
  templateUrl: './subresource.component.html',
  styleUrls: ['./subresource.component.scss']
})
export class SubresourceComponent implements OnInit {
  responseData: any[] | undefined = [];
  subResourceContent = [];

  

  constructor(private contentfulservice: ContentfulService) { }
  offsetFlag = true;
  mainText: Observable<[]>;
  heading: Observable<[]>;
  paragraph: Observable<[]>;
  unorderedList: Observable<[]>;
  listItem: Observable<[]>;
  listItem1: Observable<[]>;
  subhead: Observable<[]>;
  mainTexts
  shortStickyIsi:any;

  ngOnInit() {
    // this.getSubResource();
    this.getContent();
    this.getShortStickIsi();

  }

  scrollToElement(element: HTMLElement) {
    element.scrollIntoView({ behavior: "smooth" });
  }

  private getElementById(id: string): HTMLElement {
    console.log("element id : ", id);
    const element = document.getElementById(id);
    return element;
  }

  scrollToElementById(id: string) {
    const element = this.getElementById(id);
    this.scrollToElement(element);
  }


  @HostListener('window:scroll', ['$event']) getScrollHeight(event) {
    // console.log(window.pageYOffset, event);
    if(window.pageYOffset > 530 )
      this.offsetFlag = false;
    else
      this.offsetFlag = true;
  }


  getShortStickIsi(){
    this.contentfulservice.getdata('5cyjaHnjjHq652K59o2LuN').subscribe(res =>{
      console.log(res.fields);
      this.shortStickyIsi = res.fields.shortStickyIsi
    });

  }

  _returnHtmlFromRichText(richText) {
    if (richText === undefined || richText === null || richText.nodeType !== 'document') {
      return '<p>Loading...</p>';
    }
    return documentToHtmlString(richText);
}

  getContent(){
    this.contentfulservice.getdata('60ilGfimhTUiGmXOxai0n9').subscribe(res =>{
      this.mainText = res.fields.prescribingInformationCopy.content[0].content[0].value
    })
  }

  masterContent:any;
  getAllContents(){
    this.contentfulservice.getAlldata().subscribe(res =>{
      this.masterContent = res.items;
      this.getShortSticky();
    });
  }

  getShortSticky(){
    this.contentfulservice.getdata('5cyjaHnjjHq652K59o2LuN').subscribe(res =>{
      res.fields.shortStickyIsi.content.forEach((ele)=>{
        ele.content.forEach((elem)=>{
          this.subResourceContent = elem
          console.log(this.subResourceContent)
        })
      })
    })
  }
}


