import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { ContentfulService } from 'src/app/services/contentful.service';

@Component({
  selector: 'app-pharmacy',
  templateUrl: './pharmacy.component.html',
  styleUrls: ['./pharmacy.component.scss']
})
export class PharmacyComponent implements OnInit {

  data: any
  myControl = new FormControl('');
  options: string[] = ['Tablet'];
  medList: Array<any> = [];
  medGram: Array<any> = [];
  medQty: Array<any> = [];
  mG: string[] = ['200MG' , '500MG'];
  // optionsss: string[] = ['28', '30', '42', '60' , '180']
  filteredOptions: Observable<string[]>;
  filteredOptionss: Observable<string[]>;
  filteredOptionsss: Observable<string[]>;

  constructor(private contentfulservice : ContentfulService) { 

    this.medList = [
      {name: "Tablet" },
    ]

    this.medGram = [
      { name: "200 MG" },
      { name: "500 MG" },
    ]

    this.medQty = [
      {  name: "28" },
      {  name: "30" },
      {  name: "42" },
      {  name: "60" },
      {  name: "180" }
    ]
  }

  ngOnInit() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
  }

  getData(){
    this.contentfulservice.getMedData().subscribe(res =>{
      this.data = res
    })
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }

}

