import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UsersComponent } from './users/users.component';
import { AboutUsComponent } from './users/about-us/about-us.component';
import { InterventionMapComponent } from './users/intervention-map/intervention-map.component';
import { ResourceComponent } from './resource/resource.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';
import { QsaVideoComponent } from './qsa-video/qsa-video.component';
import { QsaGinaComponent } from './qsa-gina/qsa-gina.component';
import {ContactUsComponent} from './contact-us/contact-us.component';
import { UserComponent } from './user/user.component';
import { PharmacyComponent } from './pharmacy/pharmacy.component';

const routes: Routes = [
  // { path: '', pathMatch: 'full', redirectTo: 'users' },

  {
    path:'',
    component:UsersComponent,
  },

  {
    path:'about-us',
    component:AboutUsComponent,
  },

  {
    path:'contact-us',
    component:ContactUsComponent,
  },

  {
    path:'intervention',
    component:InterventionMapComponent,
  },

  {
    path:'resource/pbs/:id',
    component:ResourceComponent,
  },

  {
    path:'home/pbs',
    component:LandingPageComponent,
  },

  {
    path:'privacy-policy',
    component: PrivacyPolicyComponent,
  },

  {
    path:'terms-of-use',
    component: TermsOfUseComponent,
  },

  {
    path: 'pdfViewer',
    component:PdfViewerComponent,
  },
  {
    path: 'qsa-video',
    component:QsaVideoComponent,
  },
  {
    path: 'gina',
    component:QsaGinaComponent,
  },

  // {
  //   path: 'pharmacy',
  //   component:PharmacyComponent,
  // },
  
  {
    path: '**',
    component:UsersComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FeatureRoutingModule { }
