import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { ContentfulService } from 'src/app/services/contentful.service';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MatSidenavContent } from '@angular/material';


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  @ViewChild('sidenavContent', {static:false}) sidenavContentScrollable: MatSidenavContent;

  myControl = new FormControl('');
  options: string[] = ['Primary Biliary Cholangitis (PBS)'];
  filteredOptions: Observable<string[]>;
  form: FormGroup;
  submitted = false;
  mainText: Observable<[]>;
  subheadtext: Observable<[]>;
  subheadpara: Observable<[]>;
  subheadtext1: Observable<[]>;
  subheadpara1: Observable<[]>;
  subheadtext2: Observable<[]>;
  subheadpara2: Observable<[]>;
  subheadtext3: Observable<[]>;
  subheadpara3: Observable<[]>;
  getImg: string;
  siteKey = environment.siteKey
  formTitle: any;
  mainContent: any;
  reqId : any
  hi

  constructor( private contentfulservice:ContentfulService,private http :HttpClient,
    private formBuilder: FormBuilder, private router: Router, private titleService : Title) { }

    
    
     ngOnInit():void {

      this.form = this.formBuilder.group({
        goThrough: ['', Validators.required],
        acceptTerms: [false, Validators.requiredTrue],
        recaptcha: ['', Validators.required]
    });

    this.contentfulservice.getdata('4L2umR10iUjZAWQbZPxHSI').subscribe(res =>{
      console.log(res.fields.seoTitle)
      this.titleService.setTitle(res.fields.seoTitle)
    })

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );

     this.contentfulservice.getDetails().subscribe((res)=>{
       this.reqId =  res.id
     })
     
      this.getMaintext();
      this.getDropdown();
      this.getDropdown1();
      this.getDropdown2();
      this.getDropdown3();
      this.getvalue();
      this.getPrivacyContent();
      this.getMedData();
      // this.getA();
      this.getGraphicImg();
      }

  private _filter(value: string): string[] {

    const filterValue = value.toLowerCase();
    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }

  getA(){
     this.contentfulservice.getAccessToken().subscribe(res =>{
      this.hi  = console.log(res)
    })
    
  }

  onSubmit(id) {
    this.submitted = true 
    if (!this.form.invalid) {
         this.router.navigateByUrl('resource/pbs/' + `${this.reqId}`)
    }
  }

  medName:any =[]
  getMedData(){
    this.http.get('https://api.fda.gov/drug/ndc.json?search=brand_name:%22xifaxan%22').subscribe(res =>{
      this.medName = res;
    })
  }

  get f() { return this.form.controls; }

  privacyContent:any;
  getPrivacyContent(){  
    this.contentfulservice.getdata('11HlSl2ldR6dFlSSEayoxb').subscribe(res =>{
      this.privacyContent = res.fields.label;
    })
  }

  

  getMaintext(){
    this.contentfulservice.getdata('5CVNFwPO5UwhPdnkdK7kl5').subscribe(res =>{
      this.mainText = res.fields.heading
      this.mainContent = res.fields.copyBlock
    })
  }

  getDropdown(){
      this.contentfulservice.getdata('73bZxGm3W7OiIAxfBTAyV6').subscribe(res =>{
        this.subheadtext = res.fields.subHead
        this.subheadpara = res.fields.copy.content[0].content[0].value
      })
  }

  getDropdown1(){
    this.contentfulservice.getdata('U2Q9qFdL7RN72aJIUdi7r').subscribe(res =>{
      this.subheadtext1 = res.fields.subHead
      this.subheadpara1 = res.fields.copy.content[0].content[0].value
    })
}

  getDropdown2(){
    this.contentfulservice.getdata('3ykJyiZb2eLJONcAjumY9c').subscribe(res =>{
      this.subheadtext2 = res.fields.subHead
      this.subheadpara2 = res.fields.copy.content[0].content[0].value
    })
  }
  getDropdown3(){
    this.contentfulservice.getdata('1wYZRLSRg9ZHLRM7axT1JE').subscribe(res =>{
      this.subheadtext3 = res.fields.subHead
      this.subheadpara3 = res.fields.copy.content[0].content[0].value
    })
  }

  getvalue(){
    this.contentfulservice.getdata('3B6M5mBFpi2sWMcjVqgtd').subscribe(res=>{
      this.formTitle =  res.fields.heading
    })
  }


  
  getGraphicImg(){
    this.contentfulservice.getAssets('/2tUrfuS1BZz25quZ7KNnkk/').subscribe(res=>{
      this.getImg = res.fields.file.url
      console.log(this.getImg)
    })
  }


  _returnHtmlFromRichText(richText) {
    if (richText === undefined || richText === null || richText.nodeType !== 'document') {
      return '<p>Loading...</p>';
    }
    return documentToHtmlString(richText);
}

} 
