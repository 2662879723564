import { Component, OnInit, ViewChild } from '@angular/core';
import { ResourceComponent } from '../resource/resource.component';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements OnInit {

  datas:any;
  prescribes: any
  isPdfLoaded = false;
  private pdf: PDFDocumentProxy;
  prescribePdf : PDFDocumentProxy;
  page: number = 1;
  totalPages: number = 0;
  isLoaded: boolean = false;
  isLoadeds: boolean = false;

  @ViewChild(ResourceComponent, {static: false}) childReference;

 

  constructor() { }



  ngOnInit() {
    console.log(localStorage.getItem('pdfData'))
    this.datas = JSON.parse(localStorage.getItem('pdfData'));
    this.prescribes =  JSON.parse(localStorage.getItem('prescribe'))
    this.pdf = null;
    this.isPdfLoaded = false;

  }

  onLoaded(pdf: PDFDocumentProxy) {
    this.pdf = pdf;
    this.isPdfLoaded = true;
  }

  print() {
    this.pdf.getData().then((u8) => {
        let blob = new Blob([u8.buffer], {
            type: 'application/pdf'
        });

        const blobUrl = window.URL.createObjectURL((blob));
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = blobUrl;
        document.body.appendChild(iframe);
        iframe.contentWindow.print();
    });
  }



  afterLoadComplete(pdf: any) {
    this.totalPages = pdf.numPages;
    this.isLoaded = true;
  }
  afterLoadCompleteInfo(pdfData: any) {
    this.totalPages = pdfData.numPages;
    this.isLoadeds = true;
  }

  nextPage() {
    this.page++;
  }

  prevPage() {
    this.page--;
  }

  nextPageP() {
    this.page++;
  }

  prevPageP() {
    this.page--;
  }

}


