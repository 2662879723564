import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { filter, map, mergeMap } from 'rxjs/operators';
import { AppController } from './app.controller';
import { ContentfulService } from './services/contentful.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent extends AppController implements OnInit {
  primaryColor: any;
  secondaryColor: any;
  backgroundColor: any;
  fontColor: any;
  brandYellow: any;
  brandBlue: any;
  fontStyle: any;
  pageTitle: any
  

  constructor(private router: Router,
              private route: ActivatedRoute,
              private titleService: Title,
              private contentfulservice: ContentfulService,) {
    super();
  }

  ngOnInit(): void {

    this.getColors();

    this.contentfulservice.getdata('5UK3di16QfR2lqjAGxgCec').subscribe(res =>{
      this.titleService.setTitle(res.fields.pageTitle);
    })    
  }

  private setBrowserTabTitle(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.route),
      map(route => this.getRouteFirstChild(route)),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data),
    ).subscribe(event => this.titleService.setTitle(this.buildTitle(event['title'])));
  }

  private getRouteFirstChild(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }

    return route;
  }

  private buildTitle(pageTitle: string): string {
    if (pageTitle && pageTitle !== this.trans.home) {
      return [pageTitle, this.config.appTitle].join(this.config.browserTabTitleDelimiter);
    }
    return this.config.appTitle;
  }

  getColors(){
    this.contentfulservice.getdata('6IZxVeQs4RtrktDnjp8WwB').subscribe(res =>{
      this.primaryColor = res.fields.primaryColor.value
      this.secondaryColor = res.fields.secondaryColor.value
      this.fontColor = res.fields.fontColor.value
      this.fontStyle = res.fields.fontFamily
      console.log(this.primaryColor)
      document.documentElement.style.setProperty('--primaryColor', this.primaryColor);
      document.documentElement.style.setProperty('--secondaryColor', this.secondaryColor);
      document.documentElement.style.setProperty('--fontColor', this.fontColor);
      document.documentElement.style.setProperty('--fontStyle', this.fontStyle);
    })
  }

  // getColor(){
  //   this.contentfulservice.getdata('56NNrSWjL6a8FJg1KfXGY9').subscribe(res =>{
  //     this.brandBlue = res.fields.primaryColor.value
  //     this.brandYellow = res.fields.secondaryColor.value
  //     this.fontStyle = res.fields.fontFamily
  //     document.documentElement.style.setProperty('--brandBlue', this.brandBlue);
  //     document.documentElement.style.setProperty('--brandYellow', this.brandYellow);
  //     document.documentElement.style.setProperty('--fontStyle', this.fontStyle);
  //   })
  // }

}
