import { Component, OnInit } from '@angular/core';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { ContentfulService } from 'src/app/services/contentful.service';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss']
})
export class TermsOfUseComponent implements OnInit {
  termsTitle: any;

  constructor(private contentfulservice: ContentfulService) { }

  ngOnInit() {
    this.getPrivacyPolicy()
  }


  public termsOfUse:any;
  getPrivacyPolicy(){
    this.contentfulservice.getdata('27k2iavDz5jNuYzT9lsAL6').subscribe(res =>{
      console.log(res.fields);
      this.termsOfUse = res.fields.termsOfUse
    });

  }

  _returnHtmlFromRichText(richText) {
    if (richText === undefined || richText === null || richText.nodeType !== 'document') {
      return '<p>Loading...</p>';
    }
    return documentToHtmlString(richText);
}

}
