import { Component, OnInit } from '@angular/core';
import { ContentfulService } from 'src/app/services/contentful.service';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { INLINES } from '@contentful/rich-text-types';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(private contentfulservice: ContentfulService) { }

  ngOnInit() {
    this.getFooterContent();
  }

  footerContents:any;
  getFooterContent(){
    this.contentfulservice.getdata('hpV7AIbfQ7xzcePgLcX7u').subscribe(res =>{
      this.footerContents = res.fields.copyright;
      console.log(this.footerContents)
    });
  }

  public options:any = {
    renderNode: {
      [INLINES. HYPERLINK]: (node, next) => {
        return (`<a href="${
          node.data.uri
        }" target="_blank">${next(
          node.content
        )}</a>`);
      }
    }
  }

  _returnHtmlFromRichText(richText) {
    if (richText === undefined || richText === null || richText.nodeType !== 'document') {
      return '<p>Loading...</p>';
    }
    return documentToHtmlString(richText, this.options);
}
  
goToLink(url: string){
  window.open(url, "_blank");
}


}
