import { Component, OnInit } from '@angular/core';
import { ContentfulService } from 'src/app/services/contentful.service';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  constructor(private contentfulservice : ContentfulService,
    private titleService: Title) { }

  

  ngOnInit() {
    this.getAboutContent();
    
      this.contentfulservice.getdata('2DKRtXnJPZJIf0vqimXbqu').subscribe(res =>{
        this.titleService.setTitle(res.fields.pageTitle)
      })
  }

  aboutUsContent:any;
  aboutUsHeading:any;
  getAboutContent(){
    this.contentfulservice.getdata('6muAQkXmyWovJ2vhvPuBLe').subscribe(res =>{
      this.aboutUsHeading = res.fields.heading;
      this.aboutUsContent  = res.fields.copyBlock;
    });
  }

  _returnHtmlFromRichText(richText) {
    if (richText === undefined || richText === null || richText.nodeType !== 'document') {
      return '<p>Loading...</p>';
    }
    return documentToHtmlString(richText);
}



}

