import { Component, OnInit } from '@angular/core';
import { ContentfulService } from 'src/app/services/contentful.service';

@Component({
  selector: 'app-chat-bot',
  templateUrl: './chat-bot.component.html',
  styleUrls: ['./chat-bot.component.scss']
})
export class ChatBotComponent implements OnInit {

  getImg:any

  constructor(private contentfulservice : ContentfulService) { }

  ngOnInit() {
    this.getGraphicImg()
  }

  scrollToElement(element: HTMLElement) {
    element.scrollIntoView({ behavior: "smooth" });
  }

  private __getElementById(id: string): HTMLElement {
    console.log("element id : ", id);
    const element = document.getElementById(id);
    return element;
  }

  scrollToElementById(id: string) {
    const element = this.__getElementById(id);
    this.scrollToElement(element);
  }

  getGraphicImg(){
    this.contentfulservice.getAssets('/NrQqbpYDVRpR4xzgssc19/').subscribe(res=>{
      this.getImg = res.fields.file.url
    })
  }

}
