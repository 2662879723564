import { Component, OnInit } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ContentfulService } from 'src/app/services/contentful.service';
import { environment } from 'src/environments/environment';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})

export class LandingPageComponent implements OnInit {

  panelOpenState = false;
  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  step:number = 1 ;
  logo:string;
  mainHead:Observable<[]>;
  mainContent:Observable<[]>;
  mainSub:Observable<[]>;
  unordered:Observable<[]>;
  listed1:Observable<[]>;
  listed2:Observable<[]>;
  listed3:Observable<[]>;
  landFootImg: String;
  copyRights: Observable<[]>;
  siteKey = environment.siteKey
  sampleImg: string;
  prescribe: any;
  dynamicMsg: any;
  landingForm : FormGroup;
  captchaForm!: FormGroup;
  submitted = false;
  isSubmitted = false;
  reqId : any


   
  constructor( private _formBuilder: FormBuilder, private contentfulservice:ContentfulService,
    private router: Router, private titleService : Title,private http: HttpClient,
    private toast : ToastrService, ) {}


  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });

    this.contentfulservice.getDetails().subscribe((res)=>{
      this.reqId =  res.id
    })
    
    
    this.contentfulservice.getdata('4L2umR10iUjZAWQbZPxHSI').subscribe(res =>{
      console.log(res.fields.seoTitle)
      this.titleService.setTitle(res.fields.seoTitle)
    })

    this.landingForm = new FormGroup({
      email: new FormControl(	'',	[Validators.required]),
      firstName: new FormControl(	'',	[Validators.required]),
      lastName: new FormControl(	'',	[Validators.required]),
      npi: new FormControl(''),
     
   }); 

   this.captchaForm = new FormGroup({
    captcha: new FormControl(	'',	[Validators.required]),
    acceptTerms: new FormControl(	'',	[Validators.required]),
   })

    this.getHizentraimage();
    this.getMainhead();
    this.getListedtext();
    this.getInformation();
    this.getCslimage();
    this.getCopyright();
    this.getSampleimage();
    this.getPrivacyContent();
    this.getPrescribe();
    this.getDynamicmsg()
  }

    scrollToElement(element: HTMLElement) {
    element.scrollIntoView({ behavior: "smooth" });
  }

  private __getElementById(id: string): HTMLElement {
    console.log("element id : ", id);
    const element = document.getElementById(id);
    return element;
  }

  scrollToElementById(id: string) {
    const element = this.__getElementById(id);
    this.scrollToElement(element);
  }

  next(){
    this.submitted = true 
    if(!this.captchaForm.invalid){
      this.step = this.step + 1;
    }
  }

   get f() { return this.captchaForm.controls; }
  get for() { return this.landingForm.controls; }

  userData(user){
    this.isSubmitted = true
    if(!this.landingForm.invalid){
      let url = 'https://api.qsasupport.com/v1/Resources/access';
      this.landingForm.reset();
      this.http.post(url, user).subscribe(res => res)  
      this.router.navigateByUrl('resource/' + `${this.reqId}`)
    } 
}
  


  getHizentraimage(){
    this.contentfulservice.getAssets('/6yYBTOXFLaakiOU4Jkly9t/').subscribe(imgData => 
      this.logo = imgData.fields.file.url
    ); 
  }

  getMainhead(){
    this.contentfulservice.getdata('7GLDKBpd58o27K30mVbklP').subscribe(res =>{
      this.mainHead = res.fields.heading;
      this.mainContent = res.fields.copyBlock
    })
  }
  privacyContent:any;
  getPrivacyContent(){  
    this.contentfulservice.getdata('5QKXAS9MlInWFsh7BQrZlW').subscribe(res =>{
      this.privacyContent = res.fields.label;
    })
  }

  getListedtext(){
    this.contentfulservice.getdata('6ZUu0bWdIV2JS9CcvAeb6m').subscribe(res =>{
      this.mainSub = res.fields.heading;
      this.unordered = res.fields.copyBlock.content[0].content[0].value;
      this.listed1 = res.fields.copyBlock.content[1].content[0].content[0].content[0].value;
      this.listed2 = res.fields.copyBlock.content[1].content[1].content[0].content[0].value;
      this.listed3 = res.fields.copyBlock.content[1].content[2].content[0].content[0].value;
      console.log(this.listed3)
      
    })
  }

  public safetyInformation:any;
  getInformation(){
    this.contentfulservice.getdata('60ilGfimhTUiGmXOxai0n9').subscribe(res =>{
      this.safetyInformation = res.fields.prescribingInformationCopy;
    })
  } 

  _returnHtmlFromRichText(richText) {
    if (richText === undefined || richText === null || richText.nodeType !== 'document') {
      return '<p>Loading Text...</p>';
    }
    return documentToHtmlString(richText);
}

getCslimage(){
  this.contentfulservice.getAssets('/2aKt26etDN7EJwb2kqn1MI/').subscribe( res=>{
    this.landFootImg = res.fields.file.url
  });
}

  getSampleimage(){
    this.contentfulservice.getAssets('/IKaGaQRrtvpv0Z0LYaWyU/').subscribe(imgData => 
      this.sampleImg = imgData.fields.file.url
    );
  }

  getCopyright(){
    this.contentfulservice.getdata('7v86zPBhy8NW8Rr6tzgvvO').subscribe(res =>{
      this.copyRights = res.fields.copyright
      console.log(this.copyRights)
    })
  }

  getPrescribe(){
    this.contentfulservice.getdata('5cyjaHnjjHq652K59o2LuN').subscribe(res =>{
      this.prescribe = res.fields.safetyInformation
    })
  }

  getDynamicmsg(){
    this.contentfulservice.getdata('5x4cLCOYWcW1TH8X0LnPP4').subscribe(res =>{
      this.dynamicMsg = res.fields.heading
    })
  }

}



