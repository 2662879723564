import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MainCoreComponent } from './core/components/main-core/main-core.component';
// import { routerConfig } from './core/config/router-config.const';

const routes: Routes = [

  // {
  //   path:'',
  //   component:MainCoreComponent,
  //   children:[
  //     {
  //       path:'',
  //       component:UsersComponent
  //     }
  //   ]
    
  // },

  { 
    path: '', component: MainCoreComponent, children: [
      { path: '', loadChildren: () => import('./features/feature.module').then(m => m.FeatureModule) }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
