import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Pipe, PipeTransform} from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ContentfulService } from 'src/app/services/contentful.service';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import Player from '@vimeo/player';

@Component({
  selector: 'app-qsa-video',
  templateUrl: './qsa-video.component.html',
  styleUrls: ['./qsa-video.component.scss']
})

@Pipe({ name: 'safe' })

export class QsaVideoComponent implements OnInit {

  constructor(private titleService: Title,private contentfulservice : ContentfulService) { }
  
  @ViewChild('playerContainer' , {static:false}) playerContainer: ElementRef;
  
  videoUrl: any

  ngOnInit() {
    this.getQsaContent();
    this.getVimeo();

    this.contentfulservice.getdata('3QmTZhkKxdkHBK7b4nrZuS').subscribe(res =>{
      console.log(res.fields.seoTitle)
      this.titleService.setTitle(res.fields.seoTitle)
    })
  }
  qsaContent:any;
  qsaHeading:any;
  getQsaContent(){
    this.contentfulservice.getdata('4GBu1aoPWpCQCUS4h1vypO').subscribe(res =>{
      this.qsaHeading = res.fields.heading;
      this.qsaContent  = res.fields.copyBlock;
    });
  }

  _returnHtmlFromRichText(richText) {
    if (richText === undefined || richText === null || richText.nodeType !== 'document') {
      return '<p>Loading...</p>';
    }
    return documentToHtmlString(richText);
}

  // videoURL = "https://www.youtube.com/embed/LFoz8ZJWmPs";

  // transform(url) {
  //   return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  //    }

  getVimeo(){
    this.contentfulservice.getdata('54oKujDy6Bwh97UCYNuyP3').subscribe(res=>{
       this.videoUrl = res.fields.videoUrl;
       const player = new Player(this.playerContainer.nativeElement, { url: this.videoUrl , width : 700 });
       player.play().then(() => {
       });
    })
    
  }

  

}
