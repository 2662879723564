import { Component, Input, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ContentfulService } from 'src/app/services/contentful.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  animations: [
    trigger('slideInOut', [
      state(
        'in',
        style({
          transform: 'translate3d(0,0,0)',
          opacity:1,
        })
      ),
      state(
        'out',
        style({
          transform: 'translate3d(100%, 0, 0)',
          opacity:0,
        })
      ),
      transition('in => out', animate('900ms ease-in-out')),
      transition('out => in', animate('1300ms ease-in-out')),
    ]),
  ],
})
export class SideNavComponent implements OnInit {
  nav_position: string = 'end';
  QSAlogo: string

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );


  constructor(private breakpointObserver: BreakpointObserver, private contentfulservice: ContentfulService) {

  }

  ngOnInit():void{
    this.getHizentraimage();
    this.getMenuDetails();
    this.getMenu2Details();
  }

  menuState: string = 'out';

  toggleMenu() {
    this.menuState = this.menuState === 'out' ? 'in' : 'out';
  }

  getHizentraimage(){
    this.contentfulservice.getAssets('/31lqK7ynnfTX0sTRjDH4bT/')
    .subscribe(
      imgData => this.QSAlogo = imgData.fields.file.url,
      err => console.log(err)
    );
  }

  new = ''
  newLink = ''
  getMenuDetails(){
    this.contentfulservice.getdata('4gVicKtB1tTzCKl3hFUWbN').subscribe(res =>{
      this.new = res.fields.navigationText
      this.newLink = res.fields.navigationLink
    })
  }

  about= ''
  aboutLink =''

  getMenu2Details(){
    this.contentfulservice.getdata('4sLeQiwclWW965Jo60YMO0').subscribe(res =>{
      this.about = res.fields.navigationText
      this.aboutLink = res.fields.navigationLink
    })
  }

  
 
} 
