import { Component, OnInit } from '@angular/core';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { ContentfulService } from 'src/app/services/contentful.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  privacyTitle: any;

  constructor(private contentfulservice: ContentfulService) { }

  ngOnInit() {
    this.getPrivacyPolicy()
  }


  public privacy:any;
  getPrivacyPolicy(){
    this.contentfulservice.getdata('7grYxjz00bfHYKx4b7dVjc').subscribe(res =>{
      console.log(res.fields);
      this.privacy = res.fields.privacy
    });

  }

  _returnHtmlFromRichText(richText) {
    if (richText === undefined || richText === null || richText.nodeType !== 'document') {
      return '<p>Loading...</p>';
    }
    return documentToHtmlString(richText);
}

}
