import { Component, OnInit } from '@angular/core';
import { ContentfulService } from 'src/app/services/contentful.service';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  constructor(private contentfulservice : ContentfulService) { }

  ngOnInit() {
    this.getQsaContent();
  }
  qsaContent:any;
  qsaHeading:any;
  getQsaContent(){
    this.contentfulservice.getdata('4GBu1aoPWpCQCUS4h1vypO').subscribe(res =>{
      this.qsaHeading = res.fields.heading;
      this.qsaContent  = res.fields.copyBlock;
    });
  }

  _returnHtmlFromRichText(richText) {
    if (richText === undefined || richText === null || richText.nodeType !== 'document') {
      return '<p>Loading...</p>';
    }
    return documentToHtmlString(richText);
}

}
