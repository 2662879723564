import { HttpClient,HttpHeaders,HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { createClient, Entry } from 'contentful';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContentfulService {
  httpHeaders:any

  constructor(private http:HttpClient) { }


  checkBoxSubject$ = new BehaviorSubject<any>(null);

   CONFIG ={
    contentTypeIds: {
      productId: environment.accessToken
    }
  }

  private client = createClient({
    space: environment.spaceId,
    accessToken: environment.accessToken,
  });


  id = 'access_token=Nr129JpVmw1hjQAv-zy1UM8Pk8vahuU228O0bUScylU';
  options = {
    params: new HttpParams({
      fromString: this.id
    })
  }
  
   getdata(sysId: any): Observable<any>{
   return this.http.get<any>(environment.baseApiUrl + '/spaces/' + environment.spaceId + '/environments/master/entries/' + sysId , this.options)
   .pipe(
   map(res => res))
  }

  getAssets(sysId:any):Observable<any>{
    return this.http.get<any>(environment.baseApiUrl + '/spaces/' + environment.spaceId + '/environments/master/assets' + sysId , this.options)
    .pipe(
      map(res => res)
    )
  }

    
  getAlldata(): Observable<any>{
    return this.http.get<any>(environment.baseApiUrl + '/spaces/' + environment.spaceId + '/environments/master/entries/' , this.options)
    .pipe(
    map(res => res))
   }

   getDetails(): Observable<any> {
    const url = 'https://api.qsasupport.com/v1/resources/request';
    let queryParams = new HttpParams();
    queryParams = queryParams.append('id', this.id);
    return this.http.post<any>(url, { params: queryParams });
  }

  getMailInfo(val){
    return this.http.post(environment.baseMailUrl, val).pipe(
      map(res =>res))
  }

  getAccessToken(){
    // const headersList = new HttpHeaders({ 'Ocp-Apim-Subscription-Key': 'a0ad842b478b410db99c50a9f2ae103b' });


  //   const body = { 
  //     "Tenant ID": "d6341e10-3639-4e17-a8cf-769a310903d6", 
  //     "User ID": "0568aae4-d3c2-4ffd-bf1b-b3860cad59be", 
  //     "Secret": "X4KZ@m2TiZ8l" 
  // }
    return this.http.post('https://api.mosyproxy.tk/api.discountdrugnetwork.com/gateway/auth/login?groupId=DDN6120',null)
  }

  getMedData(){
    const headersList = new HttpHeaders({ 'Ocp-Apim-Subscription-Key': 'a0ad842b478b410db99c50a9f2ae103b',
   'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6Ii1LSTNROW5OUjdiUm9meG1lWm9YcWJIWkdldyIsImtpZCI6Ii1LSTNROW5OUjdiUm9meG1lWm9YcWJIWkdldyJ9.eyJhdWQiOiI5Mjg4NDI4YS1iYWYxLTRlM2ItYWQ3Mi01NTkzMGUzZjhmNjciLCJpc3MiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC8zNGQ0OTdkZi05ZGM3LTQ5ZDktOGNiNy0zNDM1ZmE5YTUxZWYvIiwiaWF0IjoxNjcxNzc0NDU4LCJuYmYiOjE2NzE3NzQ0NTgsImV4cCI6MTY3MTc3ODM1OCwiYWlvIjoiRTJaZ1lOakp5ZGltcXpoN2wwTGhoN25zQ2k2ekFRPT0iLCJhcHBpZCI6IjkyODg0MjhhLWJhZjEtNGUzYi1hZDcyLTU1OTMwZTNmOGY2NyIsImFwcGlkYWNyIjoiMSIsImlkcCI6Imh0dHBzOi8vc3RzLndpbmRvd3MubmV0LzM0ZDQ5N2RmLTlkYzctNDlkOS04Y2I3LTM0MzVmYTlhNTFlZi8iLCJvaWQiOiIxZjdjMThiOC01NDcwLTRhN2UtYmE2OS01NDU2NDNiYTY2NWYiLCJyaCI6IjAuQVJjQTM1ZlVOTWVkMlVtTXR6UTEtcHBSNzRwQ2lKTHh1anRPclhKVmt3NF9qMmNYQUFBLiIsInN1YiI6IjFmN2MxOGI4LTU0NzAtNGE3ZS1iYTY5LTU0NTY0M2JhNjY1ZiIsInRpZCI6IjM0ZDQ5N2RmLTlkYzctNDlkOS04Y2I3LTM0MzVmYTlhNTFlZiIsInV0aSI6Im9zcG9pc254MjBLa19RZllJVVRMQUEiLCJ2ZXIiOiIxLjAifQ.mCETNh2WsMGX-tGfFiU2tXDFA2u68tg-vAdcitn7HW_UAbx_4qot67nUGUPmWt-x05OLJvGuLlbGBjFqF2bz3klg-17bPUQ532hrWfZmYXCgSvvS1Jzqgzo7S2VInbyQRD4rBZcF5hIXZyU4C-Iu5R2U_eWD8-ZUop9T5n-QT6_LmWvT_77a4-i69VOEzdQ-UQ4WqOq6YkuuPQTxpEXHkTuRL58la5S6Rf4Lrs88J0s7s5WGpGuTvXsSB4PQWoCqbPlL_enUn3gek_tR3V3LSbaCm6wERL9M-t5CJuX0otEcCht0ksZWBgoeJsGfXRNc-8iogJ45WQUXZAIOtcx0lw' });

    return this.http.get('https://api.infotransfer.com/PricingAPI/api/PricingEngineExternal/FindDrugs?groupID=DDN6120&drugName=XIFAXAN&numPharm=25&brandIndicator=G&zip=85711', {headers: headersList})

  }
}



