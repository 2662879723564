import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Entry } from 'contentful';
import { ClipboardService } from 'ngx-clipboard';
import { Observable } from 'rxjs';
import { ContentfulService } from 'src/app/services/contentful.service';
import { MatDialog } from '@angular/material/dialog';
import { RELATIVE_TEXT } from './emailbody';
import { ToastrService } from 'ngx-toastr';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { FormBuilder, FormControl, FormGroup,Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-resource',
  templateUrl: './resource.component.html',
  styleUrls: ['./resource.component.scss']
})
export class ResourceComponent implements OnInit {

  @ViewChild('closebutton', {static: false}) closebutton;
  

  hideToggle = false;
  heading: Observable<[]>;
  subhead: Observable<[]>;
  copyRights: Observable<[]>;
  landFootImg: String;
  logo: String;
  dynamicMsg: any;
  paraContent: any;
  checked : boolean = false;
  pdfValues:any = []; 
  myemailForm! : FormGroup
  provideForm! : FormGroup
  submitted = false;
  firstList: any;
  firstListUrl: any;
  firstListValue: any;
  firstListUrlValue: any;
  secondListValue: any;
  secondList: any;
  secondListUrl: any;
  secondListValue2: any;
  secondListValue2Url: any;
  secondListValue2Sl: any;
  secondListValue2Tl: any;
  secondListValue2Fl: any;
  secondListValue2TlUrl: any;
  thirdList: any;
  resourceErrorMessage: "Please select any resource"
  reqId:any
  getfile:any
  routeListener
  userId
     
  
  
  constructor(private contentfulservice : ContentfulService,private router: Router,
    private clipboardService: ClipboardService,private http: HttpClient,
    private dialogRef:MatDialog, private toast : ToastrService,private route :ActivatedRoute,
    private fb :FormBuilder, private titleService:Title) {

    
    }

    scrollToElement(element: HTMLElement) {
    element.scrollIntoView({ behavior: "smooth" });
  }

  private __getElementById(id: string): HTMLElement {
    const element = document.getElementById(id);
    return element;
  }

  scrollToElementById(id: string) {
    const element = this.__getElementById(id);
    this.scrollToElement(element);
  }

  ngOnInit() {

    document.getElementById("head-content").scrollIntoView();

    this.getAllContents();

    this.myemailForm = this.fb.group({
      fromEmail : ['support@qsasupport.com'],
      toEmail : ['',[Validators.required]],
      subject : ['A resource has been shared with you'],
      body : ['']
    })

    this.contentfulservice.getdata('2vE5siW9tDgb9s6Y0Gq1xc').subscribe(res =>{
      console.log(res.fields.seoTitle)
      this.titleService.setTitle(res.fields.pageTitle)
    })


    this.routeListener = this.route.params.subscribe(params => {
      this.userId = params['id'];        
      console.log(this.userId);
    });

    this.provideForm = new FormGroup({
      email: new FormControl(	'',	[Validators.required]),
      id: new FormControl(`${this.userId}`,  [Validators.required]),
   }); 
   

    this.getInformation();
    this.getCopyright();
    this.getLogoimage();
    this.getCslimage();
    this.getDynamicmsg();
    this.getContent();
    this.getTopHeading();
    this.getTopDropdown();
    this.getTopListed();
    this.getTopListed2();
    this.getPrescribe();
    this.getPrescribeFile();
    this. getTopListedDemo()   
  }


  get f() { return this.provideForm.controls; }


  provideEmail(user){
    this.submitted = true 
      if(!this.provideForm.invalid){
        let url = 'https://api.qsasupport.com/v1/resources/request';
        this.http.post(url, user).subscribe(res => res) 
        this.toast.success('Email Submitted')
        this.router.navigateByUrl('/resource/' + `${this.userId}`)
      }
      this.provideForm.get('email').reset();
  }


  masterContent:any;
  getAllContents(){
    this.contentfulservice.getAlldata().subscribe(res =>{
      this.masterContent = res.items;
      this.getResourceContent(); 
    });
  }


  
  resourceContents = [];
  getResourceContent(){
   this.contentfulservice.getdata('7ABF6Ux8vSo5tNLghu2HyE').subscribe(res =>{
      res.fields.healthToolsOnOgeqsa.forEach((element) =>  {
       let index = this.masterContent.filter( data => data.sys.id == element.sys.id);
       if(index){
        this.resourceContents.push(index[0]);
         if(res.fields.healthToolsOnOgeqsa.length == this.resourceContents.length){
            this.groupResources();
         }
       }
      });
    })
  }

  resourceData:any;
  groupResources(){
    var groups = new Set(this.resourceContents.map(item => item.fields.audiences.sys.id));
    this.resourceData = [];
    groups.forEach(g => 
        this.resourceData.push({
          name: g, 
          values: this.resourceContents.filter(i => i.fields.audiences.sys.id === g)
        }
    ))
    if(groups.size == this.resourceData.length){
        this.resourceData.forEach(resource => {
          this.getResourceAudianceTitle(resource.name).then((groupName) => { 
              resource.name = groupName;
          });
        });
    }
    console.log(this.resourceData);
  }

  getResourceAudianceTitle(id){
    let that = this;
    return new Promise(function(resolve,reject){ 
      that.contentfulservice.getdata(id).subscribe(res =>{
        resolve(res.fields.audience);
        console.log(res.fields.audience)
      },err=>{
        reject(console.log(err));

      });
    });
  }
  selectedResources:any=[];
  onResourceSelect(event){
    if(event.checked){
      this.selectedResources.push(event);
    } else {
      this.selectedResources.pop(event);
    }
  }
  emailContent:any;
  generateEmailContent(){
    this.emailContent = null;
    this.emailContent = "<b> Here is a resource that might be of interest. Please open the link to view:</b> <br>"
    let resourceContent = '';
    if(this.selectedResources.length > 0){
      this.selectedResources.forEach(resource => {
        resourceContent =resourceContent+ "<br><b>"+ resource.fields.healthResourceTitle +"</b>" + this._returnHtmlFromRichText(resource.fields.teaser) + " " + resource.fields.healthResourceUrl + "<br>"
      });
    } else {
      this.toast.error(this.resourceErrorMessage)
    }
    this.emailContent = this.emailContent + resourceContent;
  }

  copySelectedResourceLinks(){
    this.pdfValues = [];
    if(this.selectedResources.length > 0){
      this.selectedResources.forEach(resource => {
        this.pdfValues.push(resource.fields.healthResourceUrl);
        this.clipboardService.copyFromContent(this.pdfValues);
        this.toast.success('Copied to clipboard')
      });
    } else {
      this.toast.error(this.resourceErrorMessage)
    }
  }

  viewPrintSelectedResourcs(){
    localStorage.clear();
    this.pdfValues = [];
     let prescribeInfo = [this.getfile]
    if(this.selectedResources.length > 0){
      this.selectedResources.forEach((resource,index) => {
        this.pdfValues.push(resource.fields.healthResourceUrl);
        console.log(this.pdfValues)
        localStorage.setItem('pdfData',JSON.stringify(this.pdfValues));
        localStorage.setItem('prescribe' , JSON.stringify(prescribeInfo))
        if(this.selectedResources.length == index + 1){
          window.open('/pdfViewer')
        }
      });
    } else {
      this.toast.error(this.resourceErrorMessage)
    }
  }


  mailPdf(event){
    let index = this.pdfValues.indexOf(event);
    if(index === -1){
      return this.pdfValues.push(event);
      }else{
        this.pdfValues.splice(index,1);
      }
    }
     
    get result() {
       return this.resourceContents.filter(item => item.fields);
    }


  onSubmit(){
      this.myemailForm.value.body = encodeURI(this.emailContent);
      this.contentfulservice.getMailInfo(this.myemailForm.value).subscribe(res =>{
      this.closeModal();
    })
    
  }


  closeModal() {
    this.closebutton.nativeElement.click();
  }

  // getUserFormData(data:any){
  //   this.contentfulservice.getMailInfo(data).subscribe((res)=>{
  //     return res
  //   })
  // }
  
  public safetyInformation:any;
  public prescribePdf:any;
  getInformation(){
    this.contentfulservice.getdata('60ilGfimhTUiGmXOxai0n9').subscribe(res =>{
      this.safetyInformation = res.fields.prescribingInformationCopy;
      this.prescribePdf = res.fields.prescribingInformationLink
    })
  } 

  public prescribe : any

  getPrescribe(){
    this.contentfulservice.getdata('5cyjaHnjjHq652K59o2LuN').subscribe(res =>{
      this.prescribe = res.fields.safetyInformation
    })
  }

  
  getPrescribeFile(){
    this.contentfulservice.getAssets('/2n1PlZuxC4Pf59Vtlc9dLZ/').subscribe(res=>{
      this.getfile = res.fields.file.url
      console.log(this.getfile)
    })
  }

  getCopyright(){
    this.contentfulservice.getdata('7v86zPBhy8NW8Rr6tzgvvO').subscribe(res =>{
      this.copyRights = res.fields.copyright
    })
  }

  getCslimage(){
    this.contentfulservice.getAssets('/2aKt26etDN7EJwb2kqn1MI/').subscribe( res=>{
      this.landFootImg = res.fields.file.url
    });
  }


  getLogoimage(){
    this.contentfulservice.getAssets('/6yYBTOXFLaakiOU4Jkly9t/').subscribe(res => 
      this.logo = res.fields.file.url
    );
    
  }

  getDynamicmsg(){
    this.contentfulservice.getdata('3A1KFdB61Lu4EM1lf7hD18').subscribe(res =>{
      this.dynamicMsg = res.fields.internalName
    })
  }

  getTopHeading(){
    this.contentfulservice.getdata('1pOh8G6IQ9bsgUvagXJrGP').subscribe(res =>{
      this.heading = res.fields.heading
      console.log(this.heading)
    })
  }
 
  getTopDropdown(){
    this.contentfulservice.getdata('6tVvWuMd9TbYtGGco1NqZ7').subscribe(res =>{
      this.firstList = res.fields.messagecontent.content[0].content[0].value
      this.firstListUrlValue = res.fields.messagecontent.content[0].content[1].content[0].value
      this.firstListUrl = res.fields.messagecontent.content[0].content[1].data.uri
      this.firstListValue = res.fields.messagecontent.content[0].content[2].value
    })
  }
  public getStartedContent:any;
  getTopListed(){
    this.contentfulservice.getdata('1pOh8G6IQ9bsgUvagXJrGP').subscribe(res =>{
      this.getStartedContent = res.fields.copyBlock;
      console.log(this.getStartedContent)
    })
  }

  getTopListedDemo(){
    this.contentfulservice.getdata('5ZxupRpE2litYEZcjx5vOK').subscribe(res =>{
      this.secondList = res.fields.messagecontent.content[0].content[1].content[0].value
      this.secondListUrl = res.fields.messagecontent.content[0].content[1].data.uri
      this.secondListValue = res.fields.messagecontent.content[0].content[2].value
      this.secondListValue2 = res.fields.messagecontent.content[0].content[3].content[0].value
      this.secondListValue2Url = res.fields.messagecontent.content[0].content[3].data.uri
      this.secondListValue2Sl = res.fields.messagecontent.content[0].content[4].value
      this.secondListValue2Tl = res.fields.messagecontent.content[0].content[5].content[0].value
      this.secondListValue2TlUrl = res.fields.messagecontent.content[0].content[5].data.uri
      this.secondListValue2Fl = res.fields.messagecontent.content[0].content[6].value

    })
  }

  getTopListed2(){
    this.contentfulservice.getdata('7HZ930SuiFoqyQtycKu9Zw').subscribe(res =>{
      this.thirdList = res.fields.messagecontent.content[0].content[0].value
    })
  }

  getContent(){
    this.contentfulservice.getdata('7srTbaSvD3c4cvi6jB2rGL').subscribe(res =>{
      this.paraContent =  res.fields.triggerCopy;
    })
  }


  tabURl=''
  getTabUrl(){
    this.clipboardService.copyFromContent(window.location.href)
    this.tabURl = window.location.href
    if(this.tabURl != ''){
      this.toast.success('URL Copied to ClipBoard')
    }else{
      this.toast.error('something went wrong')
    }
  }

  _returnHtmlFromRichText(richText) {
    if (richText === undefined || richText === null || richText.nodeType !== 'document') {
      return '<p>Loading...</p>';
    }
    return documentToHtmlString(richText);
}

}
